import React, { type FC, useContext, useState } from "react";
import { type ColorTheme } from "./color-themes/color-theme";

// eslint-disable-next-line
export const ColorThemeContext = React.createContext<[ColorTheme, React.Dispatch<React.SetStateAction<ColorTheme>>]>(null as any);

type ColorThemeProviderProps = {
	colorTheme: ColorTheme;
	children: React.ReactNode;
}

export const ColorThemeProvider: FC<ColorThemeProviderProps> = ({ colorTheme, children }) => {
	const colorThemeState = useState<ColorTheme>(colorTheme);

	return (
		<ColorThemeContext.Provider value={colorThemeState}>
			{children}
		</ColorThemeContext.Provider>
	);
};

export const useColorTheme = () => {
	const [theme] = useContext(ColorThemeContext);
	if (!theme) {
		throw new Error("useColorTheme must be used within a ColorThemeProvider");
	}
	return theme;
};