import { colors } from "../variables/colors";
import { type ColorTheme } from "./color-theme";

export const getDarkTealColorTheme = (): ColorTheme => ({
	name: "Dark Teal",
	isDarkBg: true,
	colors: {
		primaryColor: colors.topics.darkTeal,
		dark: "#0C2340",
		light: "#F5F5F5",
		cardTextColor: colors.primary.white,
		tags: {
			backgroundColor: colors.tags.lifeBg,
			color: colors.tags.lifeText,
		},
		quoteTextColor: colors.topics.darkTeal,
	}
});