import { useRef } from "react";
import { Flex, Box, Portal, useOutsideClick, Button } from "@chakra-ui/react";
import { type MainNavigationItemModel } from "~/feature/Navigation/generated-types";
import { useColorThemeVariant } from "../../../_Base/theme/ColorThemeContext";
import { useNavigationContext } from "../NavigationProvider";
import BundleIcon from "../../../_Base/components/ui/bundle-icon";
import { groupHoverAfterArrowStyle } from "..";
import { SubNavLink } from "./SubNavLink";
import { useSitecoreContext } from "~/foundation/Jss";
import { LanguageSelector } from "./LanguageSelector";

const SubNavigation = (
	{
		isOpen,
		close,
		links,
		defaultOpen,
	}:
		{
			isOpen: boolean,
			close?: () => void,
			links?: MainNavigationItemModel[],
			defaultOpen?: MainNavigationItemModel
		}) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const { activeSubmenus, setActiveSubmenus } = useNavigationContext();
	const ref = useRef(null)
	const { moduleBackgroundColor, fontColorPrimary } = useColorThemeVariant("themed")
	const { setIsMobileMenuOpen } = useNavigationContext();
	const primaryGradient = `${moduleBackgroundColor.replace('rgb', 'rgba').replace(')', ', .93)')}`;

	useOutsideClick({
		ref: ref,
		handler: () => {
			setActiveSubmenus && setActiveSubmenus({});
			close && close()
		},
	})

	return (
		<Portal>
			<Box
				sx={{
					position: "fixed",
					left: "0",
					top: "0",
					right: "0",
					bottom: "0",
					background: "rgb(34, 34, 34)",
					opacity: "0.25",
					zIndex: "20",
					width: "100vw",
					height: "100vh",
					visibility: isOpen ? "visible" : "hidden",
					transition: isOpen ? "all .6s ease-out" : "none",
				}}
			/>
			<Box
				ref={ref}
				className="fold-out-menu"
				as="nav"
				sx={{
					position: "fixed",
					left: !isRtl ? "0" : "auto",
					right: !isRtl ? "auto" : "0",
					top: "0",
					width: {
						base: "100vw", lg:
							(activeSubmenus && Object.keys(activeSubmenus).length === 0) ? "20vw" :
								(activeSubmenus && Object.keys(activeSubmenus).length === 1) ? "45vw" :
									(activeSubmenus && Object.keys(activeSubmenus).length === 2) ? "70vw" :
										(activeSubmenus && Object.keys(activeSubmenus).length === 3) ? "95vw" :
											!isOpen ? "20vw" : "20vw"
					},
					backgroundColor: { base: moduleBackgroundColor, lg: primaryGradient },
					zIndex: "300",
					height: "100svh",
					visibility: isOpen ? "visible" : "hidden",
					transition: isOpen ? "all .35s ease-out" : "all .35s ease-out",
					transform: isOpen ? ("translateX(0)") : !isRtl ? "translateX(-100%)" : "translateX(100%)",
					opacity: isOpen ? "1" : "0",
				}}
			>
				<Button
					sx={{
						position: "absolute",
						top: {
							base: "2.4375rem",
							lg: "3.9375rem"
						},
						right: !isRtl ? "3.125rem" : "auto",
						left: !isRtl ? "auto" : "3.125rem",
						zIndex: "120",
						transition: "all 0.25s ease"
					}}
					onClick={() => {
						setIsMobileMenuOpen && setIsMobileMenuOpen(false);
						setActiveSubmenus && setActiveSubmenus({});
						close && close();
					}}
				>
					<BundleIcon
						name="close"
						fill={fontColorPrimary}
						size="lg"
						width="20px"

						_groupHover={groupHoverAfterArrowStyle}
						isRtl={isRtl}
					/>
				</Button>
				<Box
					pos="relative"
					h={"100svh"}
					w={{ base: "100vw", lg: "20vw" }}
					// border={{
					// 	base: "1px solid pink",
					// 	sm: "1px solid red",
					// 	lg: "1px solid green",
					// 	xl: "1px solid blue",
					// 	"2xl": "1px solid yellow",
					// }}
					padding={
						{
							base: "3.125rem",
							lg: !isRtl ? "7.5rem 0 0 3.25rem" : "7.5rem 0 0 3.25rem",
							xl: !isRtl ? "7.5rem 3.25rem 0px" : "7.5rem 3.25rem 0px",
						}
					}
					display={{ base: "flex", lg: "block" }}
					flexDirection="column"
					justifyContent={{ base: "end", lg: "flex-start" }}
				>
					<Flex as="ul" listStyleType="none" gap={6} flexDirection="column" w={
						{
							base: "100%",
							lg: "auto",
						}
					}>
						{links && links?.map((link, index) => (
							<li key={index}>
								<SubNavLink
									link={link}
									isParentOpen={isOpen}
									activeSubmenus={activeSubmenus || {}}
									setActiveSubmenus={setActiveSubmenus as (submenus: { [key: string]: boolean }) => void}
									level={0}
									defaultOpen={defaultOpen}
									first={true}
									onClose={() => {
										setIsMobileMenuOpen && setIsMobileMenuOpen(false);
										setActiveSubmenus && setActiveSubmenus({});
										close && close();
									}}
								/>
							</li>
						))}
					</Flex>
					<Box
						as="hr"
						sx={{
							mt: "2rem",
							mb: "1rem",
							borderBottom: `1px solid ${fontColorPrimary}`,
							opacity: "0.5",
							display: { base: "block", lg: "none" },
						}}
					/>
					<Box
						sx={{
							display: { base: "block", lg: "none" },
						}}
					>
						<LanguageSelector
							onClick={() => {
								setIsMobileMenuOpen && setIsMobileMenuOpen(false);
								setActiveSubmenus && setActiveSubmenus({});
								close && close();
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Portal>
	);
};

export { SubNavigation };