import { colors } from "../variables/colors";
import { type ColorTheme } from "./color-theme";

export const getBurgundyColorTheme = (): ColorTheme => ({
	name: "Burgundy",
	isDarkBg: true,
	colors: {
		primaryColor: colors.topics.burgundy,
		dark: "#0C2340",
		light: "#F5F5F5",
		cardTextColor: colors.primary.white,
		tags: {
			backgroundColor: colors.tags.sciencesBg,
			color: colors.tags.sciencesText,
		},
		quoteTextColor: colors.topics.burgundy,
	}
});