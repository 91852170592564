import { colors } from "../variables/colors";
import { type ColorTheme } from "./color-theme";

export const getAmethystPurpleColorTheme = (): ColorTheme => ({
	name: "Amethyst Purple",
	isDarkBg: true,
	colors: {
		primaryColor: colors.topics.amethystPurple,
		dark: "#0C2340",
		light: "#F5F5F5",
		cardTextColor: colors.primary.white,
		tags: {
			backgroundColor: colors.tags.artsBg,
			color: colors.tags.artsText,
		},
		quoteTextColor: colors.topics.amethystPurple,
	}
});