/**
 * Used in backend to whitelist sizes
 * When sizes are changed or added, run `npm run build`

/**
 * Values can be:
 * w (width)
 * h (height)
 * mw (max width)
 * mh (max height)
 * cw (crop width)
 * ch (crop height)
 */
export const imageSizes = {
	none: {
		mw: 10,
	},
	defaultWide: { //16:9
		sm: {
			cw: 384,
			ch: 216,
		},
		md: {
			cw: 768,
			ch: 432,
		},
		lg: {
			cw: 1152,
			ch: 648,
		},
		xl: {
			cw: 1440,
			ch: 810,
		}
	},
	parallax: {
		sm: {
			mw: 600,
			ch: 360,
		},
		md: {
			mw: 1200,
			ch: 700,
		},
		lg: {
			mw: 1440,
			ch: 839,
		},
		xl: {
			mw: 1900,
			ch: 1110,
		}
	},
	fullImage: {
		sm: {
			mw: 384,
		},
		md: {
			mw: 768,
		},
		lg: {
			mw: 1184,
		},
		xl: {
			mw: 1440,
		}
	},
	wide: { //215 : 100
		sm: {
			cw: 384,
			ch: 179,
		},
		md: {
			cw: 768,
			ch: 357,
		},
		lg: {
			cw: 1152,
			ch: 536,
		},
		xl: {
			cw: 1440,
			ch: 670,
		}
	},
	imageCarousel: {
		sm: {
			cw: 687,
			ch: 422,
		},
		md: {
			cw: 441,
			ch: 259,
		},
		lg: {
			cw: 867,
			ch: 570,
		},
	},
	articleHeader: {
		sm: {
			mw: 992,
			ch: 250,
		},
		lg: {
			halfWidth: {
				mw: 1300,
				ch: 757,
			},
			fullWidth: {
				cw: 1920,
				ch: 370,
			},
		},
		fullImage: {
			sm: {
				mw: 600,
			},
			md: {
				mw: 1184,
			},
			lg: {
				mw: 1440,
			},
			xl: {
				mw: 1920,
			}
		}
	},
	banner: {
		sm: {
			cw: 1000,
			ch: 1000,
		},
		lg: {
			smallImage: {
				cw: 900,
				ch: 600,
			},
			bigImage: {
				cw: 900,
				ch: 900,
			},
		},
		fullImage: {
			mw: 1184,
		}
	},
	cards: {
		people: {
			cw: 191,
			ch: 191
		}
	}
};
