import { colors } from "../variables/colors";
import { type ColorTheme } from "./color-theme";

export const getOrangeColorTheme = (): ColorTheme => ({
	name: "Orange",
	isDarkBg: false,
	colors: {
		primaryColor: colors.topics.orange,
		dark: "#0C2340",
		light: "#F5F5F5",
		cardTextColor: colors.primary.dark,
		tags: {
			backgroundColor: colors.tags.thoughtsBg,
			color: colors.tags.thoughtsText,
		},
		quoteTextColor: colors.topics.orange,
	}
});