import { Box, type BoxProps, Button as ChakraButton, type ButtonProps as ChakraButtonProps, useStyleConfig, Text } from "@chakra-ui/react";
import BundleIcon, { type BundleIconProps } from "../bundleIcon";
import { Link, type LinkProps } from "../Link";
import { colors } from "../../Theme/variables/colors";
import { useTranslation } from "~/foundation/Dictionary";
import type { GlobalDictionary } from "../../../dictionary";

// Define a custom type for the size prop
export type CustomButtonSize = 'lg' | 'md';

// Extend ChakraButtonProps with the custom size type
export type ButtonProps = Omit<ChakraButtonProps, 'size'> & {
	size?: CustomButtonSize;
};

export type ButtonIconProps = ButtonProps & {
	icon: BundleIconProps['name'];
	iconPosition?: "before" | "after";
	isSidePadding?: boolean;
	iconSize?: string;
};

export type LinkButtonProps = LinkProps & BoxProps;

export type LinkButtonIconProps = LinkButtonProps & {
	icon: BundleIconProps['name'];
	iconPosition?: "before" | "after";
	isSidePadding?: boolean;
};

export type ReadmoreButtonProps = ButtonProps & {
	beforeText?: string;
};

const Button = ({
	children,
	...rest
}: ButtonProps) => {
	return (
		<ChakraButton {...rest}>
			{children}
		</ChakraButton>
	)
};

const ButtonIcon = ({
	children,
	icon,
	iconPosition = "before",
	isSidePadding = true,
	iconSize,
	...rest
}: ButtonIconProps) => {
	const { size } = rest;
	let iSize = size === 'lg' ? '1.5em' : '1.25em';
	if (iconSize) {
		iSize = iconSize
	}

	return (
		<Button
			display={"flex"}
			flexDir={iconPosition === "before" ? "row" : "row-reverse"}
			gap={"xxs"}
			{...rest}
			{...!isSidePadding && { px: "none" }}
		>
			<Box
				w={iSize}
				h={iSize}
				display={"flex"}
			>
				<BundleIcon
					fill={"currentColor"}
					name={icon}
					w={"100%"}
					h={"100%"}
				/>
			</Box>
			{children ? <Box>
				{children}
			</Box> : null}
		</Button>
	)
};

const LinkButton: React.FC<LinkButtonProps & { variant?: string }> = ({ variant, size, children, ...rest }) => {
	const styles = useStyleConfig("LinkButton", { variant, size, ...rest });

	return (
		<Link {...rest}>
			<Box
				h="fit-content"
				__css={styles}
			>
				{children}
			</Box>
		</Link>
	);
};

const LinkButtonIcon: React.FC<LinkButtonIconProps & { variant?: string }> = (
	{ variant, size, children, icon, iconPosition = "before", isSidePadding = true, ...rest }) => {
	const styles = useStyleConfig("LinkButton", { variant, size, ...rest });
	const iconSize = size === 'lg' ? '1.5em' : '1.25em';

	return (
		<Link {...rest}>
			<Box
				display={"flex"}
				flexDir={iconPosition === "before" ? "row" : "row-reverse"}
				gap={"xxs"}
				__css={styles}
				{...!isSidePadding && { px: "none" }}
			>
				<Box
					w={iconSize}
					h={iconSize}
					display={"flex"}
				>
					<BundleIcon
						fill={"currentColor"}
						name={icon}
						w={"100%"}
						h={"100%"}
					/>
				</Box>
				{children ? <Box>
					{children}
				</Box> : null}
			</Box>
		</Link>
	);
};

const ReadmoreButton: React.FC<ReadmoreButtonProps> = ({
	beforeText,
	children,
	...rest
}) => {
	const [t] = useTranslation<GlobalDictionary>();
	const before = beforeText ? beforeText : t('button.readmore.beforeText');
	return (
		<ChakraButton
			{...rest}
			gap={"xs"}
			sx={{
				'&:hover .readmore__text': {
					color: 'currentColor',
				}
			}}
		>
			<Text>
				{before}
			</Text>
			<Text
				className="readmore__text"
				color={colors.tints.black[30]}
			>
				{children}
			</Text>
		</ChakraButton>
	)
}

export { Button, ButtonIcon, LinkButton, LinkButtonIcon, ReadmoreButton };